export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const InView = () => import('../../components/inView.vue' /* webpackChunkName: "components/in-view" */).then(c => wrapFunctional(c.default || c))
export const GalleryImageComponent = () => import('../../components/gallery/ImageComponent.vue' /* webpackChunkName: "components/gallery-image-component" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesCookieElement = () => import('../../components/cmsOverrides/CookieElement.vue' /* webpackChunkName: "components/cms-overrides-cookie-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesErrorView = () => import('../../components/cmsOverrides/ErrorView.vue' /* webpackChunkName: "components/cms-overrides-error-view" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesFooterElement = () => import('../../components/cmsOverrides/FooterElement.vue' /* webpackChunkName: "components/cms-overrides-footer-element" */).then(c => wrapFunctional(c.default || c))
export const CmsOverridesHeaderElement = () => import('../../components/cmsOverrides/HeaderElement.vue' /* webpackChunkName: "components/cms-overrides-header-element" */).then(c => wrapFunctional(c.default || c))
export const UiButtonComponent = () => import('../../components/ui/ButtonComponent.vue' /* webpackChunkName: "components/ui-button-component" */).then(c => wrapFunctional(c.default || c))
export const UiHamburger = () => import('../../components/ui/Hamburger.vue' /* webpackChunkName: "components/ui-hamburger" */).then(c => wrapFunctional(c.default || c))
export const UiInputComponent = () => import('../../components/ui/InputComponent.vue' /* webpackChunkName: "components/ui-input-component" */).then(c => wrapFunctional(c.default || c))
export const SectionsArtSection = () => import('../../components/sections/ArtSection.vue' /* webpackChunkName: "components/sections-art-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsArtistBiography = () => import('../../components/sections/ArtistBiography.vue' /* webpackChunkName: "components/sections-artist-biography" */).then(c => wrapFunctional(c.default || c))
export const SectionsBackgroundImageSection = () => import('../../components/sections/BackgroundImageSection.vue' /* webpackChunkName: "components/sections-background-image-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHeroSection = () => import('../../components/sections/HeroSection.vue' /* webpackChunkName: "components/sections-hero-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsHorizontalSlider = () => import('../../components/sections/HorizontalSlider.vue' /* webpackChunkName: "components/sections-horizontal-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsPrivacyPolicy = () => import('../../components/sections/PrivacyPolicy.vue' /* webpackChunkName: "components/sections-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const SectionsTimelineSection = () => import('../../components/sections/TimelineSection.vue' /* webpackChunkName: "components/sections-timeline-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaFilterComponent = () => import('../../components/sections/Galerija/FilterComponent.vue' /* webpackChunkName: "components/sections-galerija-filter-component" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaGalleryOneRow = () => import('../../components/sections/Galerija/GalleryOneRow.vue' /* webpackChunkName: "components/sections-galerija-gallery-one-row" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaGallerySection = () => import('../../components/sections/Galerija/GallerySection.vue' /* webpackChunkName: "components/sections-galerija-gallery-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaGallerySingle = () => import('../../components/sections/Galerija/GallerySingle.vue' /* webpackChunkName: "components/sections-galerija-gallery-single" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaGalleryTwoRows = () => import('../../components/sections/Galerija/GalleryTwoRows.vue' /* webpackChunkName: "components/sections-galerija-gallery-two-rows" */).then(c => wrapFunctional(c.default || c))
export const SectionsGalerijaSingleImage = () => import('../../components/sections/Galerija/SingleImage.vue' /* webpackChunkName: "components/sections-galerija-single-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsKontaktContactSection = () => import('../../components/sections/Kontakt/ContactSection.vue' /* webpackChunkName: "components/sections-kontakt-contact-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsKontaktFooterContact = () => import('../../components/sections/Kontakt/FooterContact.vue' /* webpackChunkName: "components/sections-kontakt-footer-contact" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
